import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AuthGuard } from './components/services/api/auth/auth.guard';
import { NotFoundComponent } from './components/auth/not-found/not-found.component';

export const AppRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: './components/auth/auth.module#AuthModule'
    
  },
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: './components/main.module#MainModule',
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ]
  } 
];
