import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ProgressIndicatorService {
  private loaderSubject = new BehaviorSubject(false);
  state = this.loaderSubject.asObservable();
  constructor() {}
  show() {
    this.loaderSubject.next(true);
  }
  hide() {
    this.loaderSubject.next(false);
  }
}
