import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { shareReplay, tap } from "rxjs/operators"
import Url from '../api.url'
import { Router } from '@angular/router';
import { ProfilesService } from '../profiles/profiles.service';
import { OrdersService } from '../orders/orders.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  loginUrl = 'user/auth/'
  registerUrl = 'register'
  token 
  user 
  isAdmin;
  
  constructor(
    private http: HttpClient,
    private router: Router,
    ){}

  login(data) {
    this.setAuthCred(data);
    return this.http
      .post(Url + this.loginUrl, data)
      .pipe(
        tap(res => {
          if (res) {
              console.log(res);
              this.setSession(res);
              localStorage.setItem('user_info', JSON.stringify(res))
              this.router.navigate(["/home"]);
          }
        }),
        shareReplay()
      );
  }
  
  private setSession(authResult) {
    console.log("AUTH: ", authResult);
    // this.setName(authResult);
    // this.setPermissions(authResult);
    // this.setEmail(authResult.email);
    // this.setCompany(authResult.memberships.company[0]);
    // localStorage.setItem("id", authResult.id);
  }

  isLoggedIn(){
    if (!localStorage.getItem("token")) {
      this.logout();
      return false;
    }
    return true;
  }

  logout() {
    localStorage.clear();
    this.router.navigate(["auth"]);
  }

  setAuthCred(token) {
    localStorage.setItem("token", token);
    this.token = token;
  }
  getAuthCred(){
    return localStorage.getItem('token')
  }

}
