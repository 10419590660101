import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import { MatDialog } from "@angular/material";
import { AuthService } from "./auth.service";
import { ProgressIndicatorService } from "../../progress/progress-indicator.service";
import { HttpRequest, HttpHandler,
         HttpEvent, HttpInterceptor, HttpResponse } from "@angular/common/http";

@Injectable()
  export class AuthInterceptor implements HttpInterceptor {
    constructor(
      public  dialog   : MatDialog,
      private authAPI  : AuthService,
      private progress : ProgressIndicatorService
    ){}
    
    intercept(
      req: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {
      this.progress.show();
      req = req.clone({
        setHeaders:{
          "Authorization": this.authAPI.getAuthCred(),
          "Content-Type":"application/json"
        }  
      })
  
      return next.handle(req).pipe(
        tap(
          event => {
            if (event instanceof HttpResponse) {
              console.log("all looks good : ", event);
              if (event.body) {
                if (event.body["status"] == "401") {
                  this.authAPI.logout();
                  return;
                // } else if (event.body["status"] == "400") {
                //   this.openDialog("Error", event.body["message"]);
                //   return;
                // } else if (event.body["access_token"]) {
                //   this.authAPI.setAuthCred(event.body["access_token"]);
                }
              }
              this.progress.hide();
            }
          },
          err => {
            if (err) {
              if (err.status == 403) {
                return this.openDialog(
                  "Forbidden :/",
                  "Access to this location is denied"
                );
              }
  
              if (err.status == 404) {
                return this.openDialog(
                  "404 :(",
                  "The requested resource is not found"
                );
              }

              if (err.error )//&& !this.authService.isLoggedIn())
                return this.openDialog(
                  "Oops!",
                  "Unable to login. Please, check your credentials!"
                );

              this.openDialog("Oops!", "Something went wrong.");
            }
          }
        )
      );
    }
  
    openDialog(title: string, text: string): void {
      console.log('Title: ' + title + "\n Text: " + text)
      // const dialogRef = this.dialog.open(UnderDevelopmentComponent, {
      //   width: "500px",
      //   data: { title: title, text: text }
      // });
  
      // dialogRef.afterClosed().subscribe(result => {
      //   console.log("The dialog was closed");
      // });
    }
  }