import { Injectable } from '@angular/core';
export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
  roles?: string[];
}

const roles = {
  admin: "ADMIN",
  carrier: "CARRIER",
  broker: "BROKER",
};

const MENUITEMS = [
  {
    state: 'home',
    name: 'Home',
    type: 'link',
    icon: 'home'
  },
  {
    state: 'quotes',
    name: 'Quotes',
    type: 'link',
    icon: 'attach_money'
  },
  {
    state: 'available_loads',
    name: 'Available Loads',
    type: 'link',
    icon: 'local_shipping'
  },
  {
    state: 'tracking',
    name: 'Tracking',
    type: 'link',
    icon: 'location_on'
  },
  {
    state: 'chat',
    name: 'Chat',
    type: 'link',
    icon: 'chat'
  },
  {
    state: 'profiles',
    name: 'Profiles',
    type: 'link',
    icon: 'group',
    roles: [roles.admin]
  }
];


  



@Injectable()
export class MenuItems {
  
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
